@import "assets/styles/mixin.scss";
@import "assets/styles/variable.scss";

.root {
  position: fixed;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  top: 0;
  right: 0;
  height: fit-content;
  transition: 0.25s all linear;
  display: flex;
  flex-flow: column nowrap;
  z-index: 2;
  font-size: 0.9rem;

  @include mq(tablet) {
    max-width: 350px;
  }

  @include mq(desktop-l) {
    display: none;
  }
}

.side-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 5rem;
  width: fit-content;
  padding-right: $space-l;
  align-self: flex-end;

  @include mq(tablet) {
    padding-right: $space-xl;
  }

  svg {
    cursor: pointer;
  }
}

.side-menu-wrapper {
  width: 100%;
  max-width: 300px;
}

.side-menu {
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: #000000e0;
  z-index: -1;
  transition: right 0.3s ease-out;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: $space-2xl $space-m;
  max-width: 300px;

  @include mq(tablet) {
    max-width: 350px;
  }

  &--open {
    right: 0;
  }

  .side-content {
    gap: $space-l;
    color: $color-p-white;
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.side-link-group .side-link__item-group-container {
  display: flex;
  flex-flow: column;
  background-color: #262626;
  padding: 1rem 2rem;
  justify-content: space-between;
  align-content: space-between;
  border-radius: 12px;
  width: fit-content;
  min-width: 60%;
  text-align: left;
  gap: $space-m;

  .side-link__item {
    margin: 0;
  }
}

.side-link {
  &__item {
    line-height: 180%;
    color: white;
    font-size: 1rem;
    text-decoration: none;
    margin: 0.5rem 0;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      color: $color-p-yellow;
    }

    &--selected {
      color: $color-p-yellow;
      font-style: italic;
    }
  }

  &__item-arrow {
    width: auto;
    height: 0.6em;
    margin-left: 8px;
    transform: rotate(90deg);
    transition: all 0.25s ease;

    &--expand {
      transform: rotate(-90deg);
    }
  }

  &__item-group {
    overflow: hidden;
    max-height: 0;
    transition: 0.2s ease max-height;

    &_open {
      max-height: 100vh;
    }

    &-label {
      margin-bottom: 0;
    }

    &-label--expand {
      color: $color-p-yellow;
      font-weight: 700;
    }

    &-container {
      padding: 1rem 1.6rem;
    }
  }
}
